/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
/*
 Base HTML elements
*/
/*
    $LINKS
*/
a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
  color: #fff;
}
a:focus {
  outline: 0;
  text-decoration: none;
  color: #fff;
}
a:hover, a:active {
  outline: 0;
}

/*
    $TIPOGRAPHY
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 600;
  color: #fff;
  text-rendering: optimizelegibility;
}

/* Set headers line-height, margin bottom and font size */
h1,
.h1 {
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.5;
}

h2,
.h2 {
  margin-bottom: 0;
  font-size: 1.37rem;
  line-height: 1.5;
}

h3,
.h3 {
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.5;
}

h4,
.h4 {
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.5;
}

h5,
.h5 {
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: 1.5;
}

h6,
.h6 {
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.5;
}

p {
  margin-top: 0;
  font-weight: 400;
}

code {
  color: #fff;
  display: inline-block;
  padding: 2px 6px;
  font: 12px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid #353540;
  border-bottom: none;
}

/*
 Layout and general styles
*/
/*
 Main layout and HTML/BODY styles
*/
html {
  background: #0d0d10;
  font-size: 100%;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  color: #eee;
  font-size: 0.875rem;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  border-width: 0;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
}

ul li {
  margin-bottom: 6px;
}

/*
    $GRID
*/
.grid {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  justify-content: space-between;
}
@media (min-width: 41.87em) {
  .grid {
    justify-content: flex-start;
  }
}

.grid__1 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__1 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__1 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__1 {
    flex-basis: 49.25px;
    margin-right: 12px;
  }
}
.grid__2 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__2 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__2 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__2 {
    flex-basis: 110.5px;
    margin-right: 12px;
  }
}
.grid__3 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__3 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__3 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__3 {
    flex-basis: 171.75px;
    margin-right: 12px;
  }
}
.grid__4 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__4 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__4 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__4 {
    flex-basis: 233px;
    margin-right: 12px;
  }
}
.grid__5 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__5 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__5 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__5 {
    flex-basis: 294.25px;
    margin-right: 12px;
  }
}
.grid__6 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__6 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__6 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__6 {
    flex-basis: 355.5px;
    margin-right: 12px;
  }
}
.grid__7 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__7 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__7 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__7 {
    flex-basis: 416.75px;
    margin-right: 12px;
  }
}
.grid__8 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__8 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__8 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__8 {
    flex-basis: 478px;
    margin-right: 12px;
  }
}
.grid__9 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__9 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__9 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__9 {
    flex-basis: 539.25px;
    margin-right: 12px;
  }
}
.grid__10 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__10 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__10 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__10 {
    flex-basis: 600.5px;
    margin-right: 12px;
  }
}
.grid__11 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__11 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__11 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__11 {
    flex-basis: 661.75px;
    margin-right: 12px;
  }
}
.grid__12 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__12 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__12 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__12 {
    flex-basis: 723px;
    margin-right: 12px;
  }
}
.grid__13 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__13 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__13 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__13 {
    flex-basis: 784.25px;
    margin-right: 12px;
  }
}
.grid__14 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__14 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__14 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__14 {
    flex-basis: 845.5px;
    margin-right: 12px;
  }
}
.grid__15 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__15 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__15 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__15 {
    flex-basis: 906.75px;
    margin-right: 12px;
  }
}
.grid__16 {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - (12px / 2));
}

.grid--mobilefull .grid__16 {
  margin-bottom: 12px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
}
@media (min-width: 64em) {
  .grid--mobilefull .grid__16 {
    flex: 1 1 0;
  }
}

@media (min-width: 41.87em) {
  .grid__16 {
    flex-basis: 968px;
    margin-right: 12px;
  }
}
.grid--twocols {
  display: flex;
}

.grid--justify {
  justify-content: space-between;
}

.grid--align-right {
  justify-content: flex-end;
}

.grid--spacearound {
  justify-content: space-around;
}

.grid--spacebetween {
  justify-content: space-between;
}

.grid--flexgrow {
  flex-grow: 1;
}

.grid--nowrap {
  flex-wrap: nowrap;
}

.grid--centered {
  align-items: center;
  justify-content: center;
}

.grid--item-pushright {
  margin-left: auto;
}

/*
 Utilities as a last resource, so we can avoid some !importants
*/
/* Displays */
.block {
  display: block;
  width: 100%;
}

.inline-block {
  display: inline-block;
}

.hidden {
  display: none;
}

.visibility-hidden {
  visibility: hidden;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.inline-flex {
  display: inline-flex;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-start {
  align-items: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.relative {
  position: relative;
}

/* Margins */
.margin-bottom-xs {
  margin-bottom: 6px;
}

.margin-bottom-s {
  margin-bottom: 12px;
}

.margin-bottom {
  margin-bottom: 24px;
}

.margin-bottom-x2 {
  margin-bottom: 48px;
}

.margin-bottom-x3 {
  margin-bottom: 72px;
}

.margin-bottom-x4 {
  margin-bottom: 96px;
}

.margin-left-xs {
  margin-left: 6px;
}

.margin-left-s {
  margin-left: 12px;
}

.margin-left {
  margin-left: 24px;
}

.margin-left-x2 {
  margin-left: 48px;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right {
  margin-right: 24px;
}

.margin-right-s {
  margin-right: 12px;
}

.margin-right-xs {
  margin-right: 6px;
}

.margin-top {
  margin-top: 24px;
}

.margin-top-s {
  margin-top: 12px;
}

.margin-top-auto {
  margin-top: auto;
}

.no-margin {
  margin: 0;
}

.no-margin-imp {
  margin: 0 !important;
}

.no-padding {
  padding: 0;
}

/* Paddings */
.padding-s {
  padding: 12px;
}

.padding {
  padding: 24px;
}

.padding-x4 {
  padding: 96px;
}

.padding-top-s {
  padding-top: 12px;
}

.padding-top {
  padding-top: 24px;
}

.padding-top-x2 {
  padding-top: 48px;
}

.padding-top-x4 {
  padding-top: 96px;
}

.padding-bottom {
  padding-bottom: 24px;
}

.padding-left {
  padding-left: 24px;
}

.padding-right-s {
  padding-right: 12px;
}

.padding-right-x4 {
  padding-right: 96px;
}

.padding-hor {
  padding-left: 24px;
  padding-right: 24px;
}

.padding-hor-x2 {
  padding-left: 48px;
  padding-right: 48px;
}

.font-light {
  font-weight: 300;
}

.font-bold {
  font-weight: 600;
}

.font-italic {
  font-style: italic;
}

.capitalize {
  text-transform: capitalize;
}

.pre-wrap {
  white-space: pre-wrap;
}

.nowrap {
  white-space: nowrap;
}

.word-break {
  word-break: break-word;
}

.underline {
  text-decoration: underline !important;
}

/* Aligns */
.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.maxwidth {
  margin-left: auto;
  margin-right: auto;
  max-width: 980px;
  width: 100%;
}

/*
*
*   Classes for font- sizes, you can check them in _fonts.scss
*   Same name of the variables with the `font-` prefix

    .font-x-small {
        font-size: .9rem;
    }

    *
*/
.font-x-small {
  font-size: 0.65rem;
}

.font-small {
  font-size: 0.75rem;
}

.font-normal {
  font-size: 0.875rem;
}

.font-medium {
  font-size: 1rem;
}

.font-big {
  font-size: 1.125rem;
}

.font-bigger {
  font-size: 1.5rem;
}

.font-x-bigger {
  font-size: 2rem;
}

.font-huge {
  font-size: 2.5rem;
}

.font-x-huge {
  font-size: 3.5rem;
}

/*
*
*   Classes for colors, you can check them in _colors.scss
*   Same name of the variables with the `color-` prefix

    .color-info {
        color: #157ffb;
    }

    *
*/
.color-brand {
  color: #60ffcc;
}

.colorbg-brand {
  background-color: #60ffcc;
}

.color-primary {
  color: #41414d;
}

.colorbg-primary {
  background-color: #41414d;
}

.color-white {
  color: #fff;
}

.colorbg-white {
  background-color: #fff;
}

.color-black {
  color: black;
}

.colorbg-black {
  background-color: black;
}

.color-button {
  color: #cfd8dc;
}

.colorbg-button {
  background-color: #cfd8dc;
}

.color-disable {
  color: #343a40;
}

.colorbg-disable {
  background-color: #343a40;
}

.color-border {
  color: #353540;
}

.colorbg-border {
  background-color: #353540;
}

.color-bg {
  color: #0d0d10;
}

.colorbg-bg {
  background-color: #0d0d10;
}

.color-bg-old {
  color: #22222a;
}

.colorbg-bg-old {
  background-color: #22222a;
}

.color-topbar {
  color: #22222a;
}

.colorbg-topbar {
  background-color: #22222a;
}

.color-text {
  color: #eee;
}

.colorbg-text {
  background-color: #eee;
}

.color-text-light {
  color: #cfd8dc;
}

.colorbg-text-light {
  background-color: #cfd8dc;
}

.color-info {
  color: #137ffb;
}

.colorbg-info {
  background-color: #137ffb;
}

.color-success {
  color: #15cd72;
}

.colorbg-success {
  background-color: #15cd72;
}

.color-error {
  color: #e64759;
}

.colorbg-error {
  background-color: #e64759;
}

.color-warning {
  color: #ffa200;
}

.colorbg-warning {
  background-color: #ffa200;
}

.color-twitter {
  color: #137ffb;
}

.colorbg-twitter {
  background-color: #137ffb;
}

.color-twitch {
  color: #6441a4;
}

.colorbg-twitch {
  background-color: #6441a4;
}

.color-youtube {
  color: #e62117;
}

.colorbg-youtube {
  background-color: #e62117;
}

.color-facebook {
  color: #0056b8;
}

.colorbg-facebook {
  background-color: #0056b8;
}

.color-common {
  color: #7bc944;
}

.colorbg-common {
  background-color: #7bc944;
}

.color-rare {
  color: #40a8f4;
}

.colorbg-rare {
  background-color: #40a8f4;
}

.color-epic {
  color: #d42ae6;
}

.colorbg-epic {
  background-color: #d42ae6;
}

.color-legendary {
  color: #ffa727;
}

.colorbg-legendary {
  background-color: #ffa727;
}

.color-partner {
  color: #ffa727;
}

.colorbg-partner {
  background-color: #ffa727;
}

.color-affiliate {
  color: #40a8f4;
}

.colorbg-affiliate {
  background-color: #40a8f4;
}

.color-regular {
  color: #7bc944;
}

.colorbg-regular {
  background-color: #7bc944;
}

.color-light-grey {
  color: #a5a5b4;
}

.colorbg-light-grey {
  background-color: #a5a5b4;
}

.color-charity-animal {
  color: #7ec65d;
}

.colorbg-charity-animal {
  background-color: #7ec65d;
}

.color-charity-animal-darker {
  color: #20b13b;
}

.colorbg-charity-animal-darker {
  background-color: #20b13b;
}

.color-new-success {
  color: #00ff81;
}

.colorbg-new-success {
  background-color: #00ff81;
}

.gap-0 {
  gap: 0px !important;
}

.gap-row-0 {
  row-gap: 0px !important;
}

.gap-col-0 {
  column-gap: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.p-0 {
  padding: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.gap-1 {
  gap: 4px !important;
}

.gap-row-1 {
  row-gap: 4px !important;
}

.gap-col-1 {
  column-gap: 4px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.p-1 {
  padding: 4px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.px-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.gap-2 {
  gap: 8px !important;
}

.gap-row-2 {
  row-gap: 8px !important;
}

.gap-col-2 {
  column-gap: 8px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.p-2 {
  padding: 8px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.gap-3 {
  gap: 12px !important;
}

.gap-row-3 {
  row-gap: 12px !important;
}

.gap-col-3 {
  column-gap: 12px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.p-3 {
  padding: 12px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.px-3 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.gap-4 {
  gap: 16px !important;
}

.gap-row-4 {
  row-gap: 16px !important;
}

.gap-col-4 {
  column-gap: 16px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.p-4 {
  padding: 16px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.gap-5 {
  gap: 20px !important;
}

.gap-row-5 {
  row-gap: 20px !important;
}

.gap-col-5 {
  column-gap: 20px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.p-5 {
  padding: 20px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.gap-6 {
  gap: 24px !important;
}

.gap-row-6 {
  row-gap: 24px !important;
}

.gap-col-6 {
  column-gap: 24px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.p-6 {
  padding: 24px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.px-6 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.gap-7 {
  gap: 28px !important;
}

.gap-row-7 {
  row-gap: 28px !important;
}

.gap-col-7 {
  column-gap: 28px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.p-7 {
  padding: 28px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.px-7 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.gap-8 {
  gap: 32px !important;
}

.gap-row-8 {
  row-gap: 32px !important;
}

.gap-col-8 {
  column-gap: 32px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.p-8 {
  padding: 32px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.px-8 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.gap-9 {
  gap: 36px !important;
}

.gap-row-9 {
  row-gap: 36px !important;
}

.gap-col-9 {
  column-gap: 36px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.p-9 {
  padding: 36px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.px-9 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.gap-10 {
  gap: 40px !important;
}

.gap-row-10 {
  row-gap: 40px !important;
}

.gap-col-10 {
  column-gap: 40px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.p-10 {
  padding: 40px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.px-10 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.gap-11 {
  gap: 44px !important;
}

.gap-row-11 {
  row-gap: 44px !important;
}

.gap-col-11 {
  column-gap: 44px !important;
}

.mb-11 {
  margin-bottom: 44px !important;
}

.my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.mt-11 {
  margin-top: 44px !important;
}

.ml-11 {
  margin-left: 44px !important;
}

.mr-11 {
  margin-right: 44px !important;
}

.pl-11 {
  padding-left: 44px !important;
}

.p-11 {
  padding: 44px !important;
}

.pb-11 {
  padding-bottom: 44px !important;
}

.pt-11 {
  padding-top: 44px !important;
}

.px-11 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.gap-12 {
  gap: 48px !important;
}

.gap-row-12 {
  row-gap: 48px !important;
}

.gap-col-12 {
  column-gap: 48px !important;
}

.mb-12 {
  margin-bottom: 48px !important;
}

.my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.ml-12 {
  margin-left: 48px !important;
}

.mr-12 {
  margin-right: 48px !important;
}

.pl-12 {
  padding-left: 48px !important;
}

.p-12 {
  padding: 48px !important;
}

.pb-12 {
  padding-bottom: 48px !important;
}

.pt-12 {
  padding-top: 48px !important;
}

.px-12 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.visuallyhidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.text-break {
  word-break: break-all;
}

.rotate-90 {
  transform: rotate(90deg);
  transition: all 0.1s ease-out;
}

.rotate-minus90 {
  transform: rotate(-90deg);
  transition: all 0.1s ease-out;
}

.details-label {
  color: #9e9e9e;
  font-weight: 400;
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-help {
  cursor: help;
}

.button-as-anchor {
  width: unset !important;
  background: transparent !important;
  border: 0 !important;
  padding: 0 !important;
  text-align: unset !important;
  cursor: pointer;
}

.rarity {
  text-transform: capitalize;
}
.rarity.common {
  color: #7bc944;
}
.rarity.rare {
  color: #40a8f4;
}
.rarity.epic {
  color: #d42ae6;
}
.rarity.legendary {
  color: #ffa727;
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sticky {
  position: sticky;
  top: var(--top-menu-height);
}

.whitespace-nowrap {
  white-space: nowrap;
}

@media (max-width: 669px) {
  .hidden-sm {
    display: none;
  }
}